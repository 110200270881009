import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { _T } from '../_i18n/i18n.helper'
import UIStore from '../store/UIStore'
import MainMenu from '../_components/Navigation/MainMenu'
import Breadcrumb from '../_components/Navigation/Breadcrumb'
import manageForm from '../_components/forms/manageForm'
import formStore from './form.store'
import Field from '../_components/forms/Field'
import * as userService from './user.service'
import { toast } from 'react-toastify'
import { API_URL } from '../config'
import FileUploader from '../_components/forms/field_components/fileUploader/FileUploader'
import Axios from 'axios'
import styles from './myAccount.module.scss'
import TagManager from 'react-gtm-module'
import EnergyModal from '../Energy/EnergyModal'


const MyAccount = (props) => {
  const { errors, touched, onChange, onBlur } = props; // NOSONAR

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [modalEnergyIsOpen, setModalEnergyIsOpen] = useState(false);

  // const [data, setData] = useState({ // NOSONAR
  //   image: null,
  //   modalEnergyIsOpen: false
  // })

  const refresh = async (refreshEnergyOnly = false) => {
    let userInfos = await userService.get()
    if (refreshEnergyOnly) {
      formStore.form.energy = { ...userInfos.energy }
    } else {
      formStore.form = { ...formStore.form, ...userInfos }
    }
    
    if (formStore.form.imageId) {
      let imageData = await Axios.get(API_URL + '/file/' + formStore.form.imageId)
      setImage(imageData.data);
      // setData({
      //   ...data,
      //   image: imageData.data
      // })
    } else {
      setImage(null);
      // setData({
      //   ...data,
      //   image: null
      // })
    }
    setLoading(false)
  }
 
  useEffect(() => { refresh() }, []);

  const save = () => {
    if (image) {
      formStore.form.image = image.filepath
      formStore.form.imageId = image.id
    }
    const energy = toJS(formStore.form).energy && toJS(formStore.form).energy.fuelOil ? toJS(formStore.form).energy :  UIStore.environment_energy
    formStore.form = { ...formStore.form, energy: {...energy} };

    return props
      .validate() // NOSONAR
      .then(() => {
        userService
          .update({ data: toJS(formStore.form) })
          .then(() => {
            toast.success(_T('ACCOUNT_INFOS_SAVED'))

            const { history } = props; // NOSONAR
            history.goBack(); // NOSONAR
          })
          .catch(() => toast.error(_T('ERROR_OCCURED')))
      })
      .catch((err) => {
        toast.error(_T('ERROR_OCCURED'))
      })
  }

  const saveImage = (image) => {
    setImage(image);
    // setData({
    //   ...data,
    //   image: image
    // })
  }

  const getNoteByEnv = () => {
    let trad = 'PERSONNAL_DATA_NOTE'
    if (UIStore.isThermor) trad = 'PERSONNAL_DATA_NOTE_thermor'

    return _T(trad).replace(/([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})/gi, '<a href="mailto:$1">$1</a>')
  }

  const deleteLogo = async () => {
    await userService.deleteLogo()
    toast.success(_T('USER_LOGO_DELETED'))
    refresh()
  }

  const toggleModalEnergy = () => {
    if (modalEnergyIsOpen) refresh(true)
    setModalEnergyIsOpen(!modalEnergyIsOpen);
    // setData({
    //   ...data,
    //   modalEnergyIsOpen: !data.modalEnergyIsOpen
    // })
  }

  const openModalEnergy = () => {
    // setData({
    //   ...data,
    //   modalEnergyIsOpen: true
    // })
    setModalEnergyIsOpen(true);
  }

  const tagManagerArgs = {
    dataLayer: {
      'event': 'datalayer-ready',
      'page': {
        'arbo1': 'mon compte',
        'arbo2': null,
        'arbo3': null,
        'arbo4': null,
        'taxonomy1': 'simulateur',
        'product_taxonomy1': 'PAC AIR/EAU',
        'product_taxonomy2': 'PAC INDIVIDUELLE',
        'product_taxonomy3': null,
        'product_taxonomy4': null,
        'product_taxonomy5': null,
        'product_taxonomy6': null
      },
      'user': {
        'id': UIStore.user.id,
        'type': null,
        'company': null,
        'siret': UIStore.user.siret
      },
      'utilisateurConnecte': UIStore?.user?.id ? "Connecté" : "Déconnecté"
    },
    dataLayerName: 'PageDataLayer'
  }

  TagManager.dataLayer(tagManagerArgs)

  return (
    <div className={styles.myAccount}>
      {/*loading && <div className='loader-overlay'>
        <ClipLoader
          size={50}
          //size={"150px"} this also works
          color={"#f47e60"}
          loading={loading}
        />
      </div> */}

      <div className="header container-fluid withoutBreadcrumb">
        <MainMenu />
        <Breadcrumb title={_T('MYACCOUNT_TITLE')} />
      </div>

      <div className="main container-fluid myAccountInfos">
        <div className="row etude">
          <div className="col col-12 col-lg-3 leftCol">
            {loading ? (
              <div className={styles.loaderCenterer}>
                <div className={styles.loader}></div>
              </div>
            ) : (
              <div className="logo">
                <img
                  src={image?.filepath ? API_URL + '/' + image.filepath : UIStore.logo}
                  alt=""
                  className="logo-img"
                />
              </div>
            )}
            {/*<input type="file" name="file" id="file" className="inputfile"/>*/}
            <div style={{ position: 'relative' }}>
              <FileUploader value={image} onUploaded={saveImage} type={['image/*']} />
              <button className="btn_delete file" onClick={deleteLogo}><i></i></button>
            </div>
          </div>

          <div className="col col-12 col-lg-9">
            <div className="row">
              <div className="col col-12 col-lg-6">
                <Field
                  className="form-control"
                  type="text"
                  name="lastname"
                  placeholder={_T('ACCOUNT_LASTNAME')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.lastname}
                  errors={errors}
                  touched={touched}
                />

                <Field
                  className="form-control"
                  type="text"
                  name="firstname"
                  placeholder={_T('ACCOUNT_FIRSTNAME')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.firstname}
                  errors={errors}
                  touched={touched}
                />

                <Field
                  className="form-control"
                  type="text"
                  name="address1"
                  placeholder={_T('ACCOUNT_ADDRESS1')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.address1}
                  errors={errors}
                  touched={touched}
                />

                <Field
                  className="form-control"
                  type="text"
                  name="address2"
                  placeholder={_T('ACCOUNT_ADDRESS2')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.address2}
                  errors={errors}
                  touched={touched}
                />

                <Field
                  className="form-control"
                  type="text"
                  name="city"
                  placeholder={_T('ACCOUNT_CITY')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.city}
                  errors={errors}
                  touched={touched}
                />

                <Field
                  className="form-control"
                  type="text"
                  name="postalcode"
                  placeholder={_T('ACCOUNT_POSTALCODE')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.postalcode}
                  errors={errors}
                  touched={touched}
                />

                <Field
                  className="form-control"
                  type="text"
                  name="siret"
                  placeholder={_T('ACCOUNT_NUMBER')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.siret}
                  errors={errors}
                  touched={touched}
                />

                <Field
                  className="form-control"
                  type="text"
                  name="policyNumber"
                  placeholder={_T('ACCOUNT_POLICY_NUMBER')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.policyNumber}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="col col-12 col-lg-6">
                <Field
                  className="form-control"
                  type="text"
                  name="phone"
                  placeholder={_T('ACCOUNT_PHONE')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.phone}
                  errors={errors}
                  touched={touched}
                />

                <Field
                  className="form-control"
                  type="text"
                  name="fax"
                  placeholder={_T('ACCOUNT_FAX')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.fax}
                  errors={errors}
                  touched={touched}
                />

                <Field
                  className="form-control"
                  type="text"
                  name="email"
                  placeholder={_T('ACCOUNT_EMAIL')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.email}
                  errors={errors}
                  touched={touched}
                />

                <Field
                  className="form-control"
                  type="text"
                  name="www"
                  placeholder={_T('ACCOUNT_WWW')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.www}
                  errors={errors}
                  touched={touched}
                />

                <Field
                  className="form-control comment"
                  type="textarea"
                  name="comment"
                  placeholder={_T('ACCOUNT_COMMENT')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={formStore.form.comment}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>

            <div className="row note">
              <div className="col col-12 col-lg-12">
                <div>
                  <strong>{_T('PERSONNAL_DATA')}</strong>
                </div>
                <div dangerouslySetInnerHTML={{ __html: getNoteByEnv() }}></div>
              </div>
            </div>

            <div className="row">
              <div className="col col-12 col-lg-12">
                <button className="btn_std" onClick={openModalEnergy}>{_T('ENERGY_PRICE')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer container-fluid">
        <div className="row">
          <div className="col bottomBarre">
            <button className="btn navPrev"></button>
            <button className="btn enregistrer" onClick={save}>
              {_T('NAV_SAVE_AND_CLOSE')}
            </button>
            <button className="btn navNext"></button>
          </div>
        </div>
      </div>

      <EnergyModal modalIsOpen={modalEnergyIsOpen} toggle={toggleModalEnergy} />
    </div>
  )
}

export default manageForm(observer(MyAccount), formStore, 'form')